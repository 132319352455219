import React from "react";
import "./navbar.scss";

const Navbar = () => {
  return (
    <div className="navbar">
      <div className="navbarLogos">
        <img src="/images/Picture1.png" alt="" className="navbarLogo" />
        <img src="/images/Picture2.png" alt="" className="navbarLogo" />
      </div>
    </div>
  );
};

export default Navbar;
