import { React, useRef, useState, useEffect } from "react";
import { Survey } from "survey-react-ui";
import "./IADL.scss";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";

function SurveyComponentIADL(props) {
  const [completionDate, setCompletionDate] = useState("");
  const [visible, setVisible] = useState(true);
  const { json } = props;
  const [finalScore, setFinalScore] = useState(null);
  const componentRefIADL = useRef(null);
  const scale = 0.9;
  const maxWidthPercent = 95;
  const screenWidth = window.innerWidth;
  const maxWidth = (maxWidthPercent / 100) * screenWidth;
  const score_dict = {
    "Operates telephone on own initiative-looks up and dials numbers": 1,
    "Dials a few well-known numbers": 0,
    "Answers telephone but does not dial": 0,
    "Does not use telephone at all": 0,

    "Takes care of all shopping needs independently": 1,
    "Shops independently for small purchases": 0,
    "Needs to be accompanied on any shopping trip": 0,
    "Completely unable to shop": 0,

    "Plans, prepares and serves adequate meals independently": 1,
    "Prepares adequate meals if supplied with ingredients": 0,
    "Heats, serves and prepares meals, but does not maintain adequate diet": 0,
    "Needs to have meals prepared and served": 0,

    "Maintains house alone or with occasional assistance with heavy work": 1,
    "Performs light daily tasks such as dish washing, bed making": 0,
    "Performs light daily tasks but cannot maintain acceptable level of cleanliness": 0,
    "Needs help with all home maintenance tasks": 0,
    "Does not participate in any housekeeping tasks": 0,

    "Does personal laundry completely": 1,
    "Launders small items-rinses stockings, etc": 0,
    "All laundry must be done by others": 0,

    "Travels independently on public transportation or drives own car": 1,
    "Arranges own travel via taxi, but does not otherwise use public transportation": 0,
    "Travels on public transportation when accompanied by another": 0,
    "Travel limited to taxi or automobile with assistance of another": 0,
    "Does not travel at all": 0,

    "Is responsible for taking medication in correct dosages at correct time": 1,
    "Takes responsibility if medication is prepared in advance in separate dosage": 0,
    "Is not capable of dispensing own medication": 0,

    "Manages financial matters independently, collects and keeps track of income": 1,
    "Manages day-to-day purchases, but needs help with banking, major purchases, etc.": 0,
    "Incapable of handling money": 0,
  };

  const handleChange = (event) => {
    const responses = event.data;
    const score = calculateScore(responses);
    setFinalScore(score);
  };

  function calculateScore(responses) {
    let score = 0;

    for (const questionName in responses) {
      if (responses.hasOwnProperty(questionName)) {
        const response = responses[questionName];
        score += score_dict[response] || 0;
      }
    }

    return score;
  }

  const goBack = () => {
    props.setSelectedOption(null);
    props.setHeaderVisible(true);
  };

  const handlePrint = () => {
    const backButton = document.querySelector(".back");
    const completeButton = document.querySelector(".sv_complete_btn");
    backButton.style.display = "none";
    completeButton.style.display = "none";

    html2canvas(componentRefIADL.current, {
      scale,
      width: maxWidth,
      height: componentRefIADL.current.offsetHeight + 200,
    }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF({
        orientation: "portrait",
        unit: "mm",
        format: [275, 660],
      });
      pdf.addImage(imgData, "PNG", 6, 4);
      pdf.save("IADL_Form.pdf");
    });
    setFinalScore(null);
    setVisible(false);
    backButton.style.display = "block";
    completeButton.style.display = "block";
  };

  useEffect(() => {
    const currentDate = new Date().toISOString().split("T")[0];
    setCompletionDate(currentDate);
  });

  return (
    <div className="survey-container1" ref={componentRefIADL}>
      <div className="IADL">
        <h1>Instrumental Activities of Daily Living (IADL)</h1>
      </div>
      {visible && (
        <div className="intro">
          <div className="patient">
            <div className="id">
              <p>
                {" "}
                Fill in the patient ID or leave blank and place patient ID label
                here after printing:
              </p>
              <textarea name="" id="" cols="30" rows="10"></textarea>
            </div>
            <div className="date">
              <p>Name-Signature:</p>
              <textarea cols="30" rows="5" />
              <p>Date of completion:</p>
              <input
                id="completionDate"
                type="date"
                defaultValue={completionDate}
              />
            </div>
          </div>
          <p className="desc">
            For each category, choose the description that best describes the
            maximum performance (Only one answer can be ticked for each
            question).
          </p>
        </div>
      )}
      <Survey
        json={json}
        onComplete={handlePrint}
        onValueChanged={handleChange}
        completedHtml="<div><h1>Thank you for completing the IADL survey!</h1>  <h1>Your download has started.</h1> <h1>You can navigate back to complete more surveys.</h1></div>"
        completeButtonClass="no-print"
      />
      <div className="button_container">
        <button className="back no-print" onClick={goBack}>
          Go Back
        </button>
      </div>
      {finalScore !== null && (
        <div className="scores">
          <div className="totalScore">Total Score: {finalScore} / 8</div>
          <div className="values">
            <p>8 = High (Patient independent)</p>
            <p>0 = Low (Patient very dependent)</p>
          </div>
        </div>
      )}

      <br />
      <br />
      <br />
      <br />
      <div className="copyright">
        Source: Lawton MP and Brody EM: Assessment of older people:
        self-maintaining and instrumental activities of daily living.
        Gerontologist 9: 179–186, 1969. Copyright: Oxford University Press.
      </div>
    </div>
  );
}

export default SurveyComponentIADL;
