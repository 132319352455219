import React from "react";
import "./footer.scss";

const Footer = () => {
  return (
    <div className="footer">
      <div className="footerContent">
        <div className="footerLogo">
          <img src="/images/Picture3.jpg" alt="" className="footerImg" />
        </div>
        <div className="footerText">
          <div className="info">
            <p> German Lymphoma Alliance e.V. (GLA) </p>
            <p>Clinical Trial Center Department of Internal Medicine I</p>
            <p>Saarland University</p>
            <p>Building 41</p>
            <p>D - 66421 Homburg</p>
          </div>
          <div className="info">
            <p>Protocol code: ARCHED / GLA 2022-1</p>
            <p>EU CT number: 2022-501187-18-00</p>
            <p>Tel : +49 6841 16-15014</p>
            <p>Fax : +49 6841 16-15015</p>
            <p>E-Mail : arched@uks.eu</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
